import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Spacer } from 'ascential-horizon'
import { useIdentity } from '../providers/identityContext'
import getThemeConfig from '../providers/config/themeConfig'
import renderContent from '../utils/componentRenderer'
import Layout from '../Layout'
import Breadcrumbs from '../components/Breadcrumbs'
import Cards from '../components/Cards'
import Hero from '../components/Hero'
import Billboard from '../components/Billboard'

const TemplateArticlePage = ({ data, pageContext, location }) => {
  const {
    footerNav,
    header,
    headerAuth,
    home: { footer },
    page: {
      announcement,
      pageType,
      slug,
      hero,
      content,
      relatedArticles,
      billboard,
    },
  } = data
  const { user, isAuthorized, loggedIn, login, logout } = useIdentity()
  const {
    footer: { type },
    header: { variation },
    showBreadcrumbs,
  } = getThemeConfig()

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    const headerProps =
      variation === 'nav'
        ? {
            ...(loggedIn && headerAuth ? headerAuth : header),
          }
        : {
            ...data.home.header,
          }
    return headerProps
  }

  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    announcement,
    header: getHeaderProps(),
    footer: footerProps,
    slug,
    pageType,
    pageContext,
    location,
  }

  const userDetails = {
    location,
    isAuthorized,
    user,
    actions: { login, logout },
  }

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const breadcrumbProps = {
    crumbs,
    crumbLabel: pageContext.title,
    location,
  }

  const hasBillboard = !!billboard && loggedIn && !isAuthorized

  return (
    <Layout {...layoutProps}>
      {showBreadcrumbs && <Breadcrumbs {...breadcrumbProps} />}
      <Hero {...hero} />
      <Spacer>
        {renderContent(content, userDetails)}
        {!!relatedArticles && <Cards {...relatedArticles} />}
      </Spacer>
      {hasBillboard && <Billboard {...billboard} />}
    </Layout>
  )
}

TemplateArticlePage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const templateLandingPageQuery = graphql`
  query($id: String!, $rootId: String!) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    home: contentfulTemplateHomePage(id: { eq: $rootId }) {
      ...header
      footer {
        ...footerQuery
      }
    }
    page: contentfulTemplateArticlePage(id: { eq: $id }) {
      pageType {
        instanceName
        slug
      }
      slug
      hero {
        ...heroQuery
      }
      announcement {
        ...announcementQuery
      }
      content {
        __typename
        ... on Node {
          ...multipleComponents
        }
      }
      billboard {
        ...billboardQuery
      }
      relatedArticles {
        title
        ctaLabel
        ctaUrl
        variant
        cards {
          ...cardQuery
        }
      }
    }
  }
`

export default TemplateArticlePage
